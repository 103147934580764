import { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import { Spin } from "antd";

import LoginPage from "../pages/Login.js";

import RootLayout from "./root.js";
import { checkAuthLoader } from "../components/auth";
import ValidateToken from "../pages/ValidateToken.js";
import ResetPassword from "../pages/ResetPassword.js";
import { OrgSetup } from "../pages/Other/OrgSetup/OrgSetup.js";
import { PPMSetup } from "../pages/Other/ppmSetup/PPMSetup.js";

const Activity = lazy(() =>
  import("../pages/configuration/Activity/Activity.js")
);

const ActivityTag = lazy(() =>
  import("../pages/Other/Activity/ActivityTag.js")
);

const Employee = lazy(() =>
  import("../pages/configuration/Employee/Employee.js")
);

const RolePage = lazy(() => import("../pages/configuration/Role/Role.js"));
const UsersPage = lazy(() => import("../pages/configuration/Users/Users.js"));

const Dashboard = lazy(() => import("./../pages/Dashboard.js"));

const Country = lazy(() => import("../pages/Other/Country/Country.js"));
const Qualification = lazy(() =>
  import("../pages/Other/Qualifications/Qualification.js")
);
const TaxHeads = lazy(() => import("../pages/Other/TaxHeads/TaxHeads.js"));
const State = lazy(() => import("../pages/Other/States/State.js"));
const City = lazy(() => import("../pages/Other/City/City.js"));
const Currency = lazy(() => import("../pages/Other/Currency/Currency.js"));
const TimeZone = lazy(() => import("../pages/Other/TimeZone/TimeZone.js"));
const Salutation = lazy(() =>
  import("../pages/Other/Salutation/Salutation.js")
);
const BloodGroup = lazy(() =>
  import("../pages/Other/BloodGroup/BloodGroup.js")
);
const Band = lazy(() => import("../pages/Other/Band/Band.js"));
const ActivityTaskTag = lazy(() =>
  import("../pages/Other/Task/ActivityTaskTag/ActivityTaskTag.js")
);
const BusinessUnit = lazy(() =>
  import("../pages/Other/BusinessUnit/BusinessUnit.js")
);

const WorkLocation = lazy(() =>
  import("../pages/Other/WorkLocation/WorkLocation.js")
);
const Experience = lazy(() =>
  import("../pages/Other/Experience/Experience.js")
);
const Shift = lazy(() => import("../pages/Other/Shift/Shift.js"));

const Department = lazy(() =>
  import("../pages/Other/Department/Department.js")
);
const Designation = lazy(() =>
  import("../pages/Other/Designation/Designation.js")
);
const Education = lazy(() => import("../pages/Other/Education/Education.js"));
const WeekOff = lazy(() => import("../pages/Other/WeekOff/WeekOff.js"));
const Nationality = lazy(() =>
  import("../pages/Other/Nationality/Nationality.js")
);

const LeaveType = lazy(() => import("../pages/Other/LeaveType/LeaveType.js"));

const TenantPage = lazy(() =>
  import("../pages/configuration/Tenant/Tenant.js")
);
const BPTenantPage = lazy(() => import("../pages/BPTenant/Tenant.js"));
const Error404 = lazy(() => import("../components/error.js"));

const ForgotPassword = lazy(() => import("../pages/ForgotPassword.js"));

const contentStyle = {
  padding: 50,
  background: "rgba(0, 0, 0, 0.05)",
  borderRadius: 4,
};

const content = <div style={contentStyle} />;

export const tenantRouter = createBrowserRouter([
  { path: "/", element: <LoginPage /> },
  {
    path: "/*",
    element: <Error404 />,
  },
  {
    path: "/forgotpassword",
    element: (
      <Suspense fallback={<Spin tip="Loading">{content}</Spin>}>
        <ForgotPassword />
      </Suspense>
    ),
  },
  {
    path: "/validatetoken/:id",
    element: <ValidateToken />,
  },
  {
    path: "/resetpassword/:id",
    element: <ResetPassword />,
  },
  {
    path: "/",
    element: <RootLayout />,
    loader: checkAuthLoader,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
        loader: checkAuthLoader,
      },
      { path: "/Activity", element: <Activity />, loader: checkAuthLoader },
      {
        path: "/activity-tag",
        element: <ActivityTag />,
        loader: checkAuthLoader,
      },
      {
        path: "/activity-task-tag",
        element: <ActivityTaskTag />,
        loader: checkAuthLoader,
      },
      {
        path: "/Employee",
        element: <Employee />,
        loader: checkAuthLoader,
      },
      {
        path: "/country",
        element: <Country />,
        loader: checkAuthLoader,
      },
      {
        path: "/state",
        element: <State />,
        loader: checkAuthLoader,
      },
      {
        path: "/city",
        element: <City />,
        loader: checkAuthLoader,
      },
      {
        path: "/orgsetup",
        element: <OrgSetup />,
        loader: checkAuthLoader,
      },
      {
        path: "/ppmsetup",
        element: <PPMSetup />,
        loader: checkAuthLoader,
      },

      {
        path: "/currency",
        element: <Currency />,
        loader: checkAuthLoader,
      },
      {
        path: "/tax-heads",
        element: <TaxHeads />,
        loader: checkAuthLoader,
      },
      {
        path: "/timezone",
        element: <TimeZone />,
        loader: checkAuthLoader,
      },

      {
        path: "/salutations",
        element: <Salutation />,
        loader: checkAuthLoader,
      },
      {
        path: "/bloodgroup",
        element: <BloodGroup />,
        loader: checkAuthLoader,
      },
      {
        path: "/band",
        element: <Band />,
        loader: checkAuthLoader,
      },
      {
        path: "/businessunit",
        element: <BusinessUnit />,
        loader: checkAuthLoader,
      },
      {
        path: "/worklocation",
        element: <WorkLocation />,
        loader: checkAuthLoader,
      },
      {
        path: "/experience",
        element: <Experience />,
        loader: checkAuthLoader,
      },
      {
        path: "/shifts",
        element: <Shift />,
        loader: checkAuthLoader,
      },
      {
        path: "/qualifications",
        element: <Qualification />,
        loader: checkAuthLoader,
      },
      {
        path: "/educations",
        element: <Education />,
        loader: checkAuthLoader,
      },

      {
        path: "/departments",
        element: <Department />,
        loader: checkAuthLoader,
      },
      {
        path: "/designations",
        element: <Designation />,
        loader: checkAuthLoader,
      },
      {
        path: "/leave-types",
        element: <LeaveType />,
        loader: checkAuthLoader,
      },
      {
        path: "/weekoff",
        element: <WeekOff />,
        loader: checkAuthLoader,
      },
      {
        path: "/nationality",
        element: <Nationality />,
        loader: checkAuthLoader,
      },
      {
        path: "/tenant",
        element: <TenantPage />,
        loader: checkAuthLoader,
      },
      {
        path: "/bptenant",
        element: <BPTenantPage />,
        loader: checkAuthLoader,
      },
      {
        path: "/role",
        element: <RolePage />,
        loader: checkAuthLoader,
      },
      {
        path: "/users",
        element: <UsersPage />,
        loader: checkAuthLoader,
      },
      {
        path: "/*",
        element: <Error404 />,
      },
    ],
  },
]);
