import { useState, useEffect } from "react";
import cookies from "react-cookies";
import { Form, Button, Input, Space, Flex, Checkbox, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import logoUrl from "../assets/logo.svg";

import { useLogin, useValidateDomain } from "../hooks/useAuth";

const LoginPage = () => {
  //const logoUrl ="" //useSelector((state) => state.tenant.logoUrl);
  const subsidiaryName = "Re-Aligno"; ///useSelector((state) => state.tenant.subsidiaryName);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  //const domainValidation = useValidateDomain();
  const {
    username,
    setUsername,
    password,
    setPassword,
    isLoading,
    handleLogin,
  } = useLogin();

  //const serverValidation = domainValidation.data?.[0];

  // if (serverValidation && !serverValidation.result) {
  //   message.error(serverValidation.message || "Server validation failed", 2);
  //   return;
  // }

  // if (validatationMessage !== true) {
  //   alert(validatationMessage);
  //   return;
  // }

  // const error = 202;

  // if (error != 202) navigate("/dashboard");

  useEffect(() => {
    // const token = localStorage.getItem("token");
    const savedUsername = cookies.load("username");

    if (savedUsername) {
      setUsername(savedUsername);
    }
  }, []);

  const onFinish = () => {
    handleLogin();
  };

  const forgotShift = () => {
    navigate("/forgotpassword", { state: { email: username } });
  };

  return (
    <section className="flex items-center justify-center min-h-screen">
      <Form
        className="flex flex-col p-6 items-center justify-center"
        onFinish={onFinish}
      >
        <h2 className="text-[#3C4A60D9] roboto-medium text-[30px]">
          Welcome To
        </h2>
        <Space className="mt-7 flex items-center justify-center">
          <img src={logoUrl} className="w-16 h-16 " />
          <h5 className="text-[#7E93AB] roboto-bold text-4xl">
            {subsidiaryName}
          </h5>
        </Space>
        <h5 className="mt-[28px] text-[20px] text-[#3C4A60D9] roboto-medium">
          Intellegent Performance Homonization System
        </h5>

        <Flex vertical gap={12} className="mt-10 w-full">
          <Space direction="vertical" className="gap-4">
            <Input
              placeholder="Username"
              className="rounded-none"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />

            <Input.Password
              placeholder="Password"
              className="rounded-none"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Space>
          {/* <Form.Item name="remember" valuePropName="checked">
            <Checkbox onChange={(e) => setRememberMe(e.target.checked)}>
              Remember Me
            </Checkbox>
          </Form.Item> */}
          <Space direction="vertical" className="mt-2">
            <Button
              block
              type="primary"
              htmlType="submit"
              className="rounded-none roboto-regular"
            >
              {isLoading ? "Logging In..." : "Login"}
            </Button>
          </Space>
          <Button
            type="link"
            className="text-primary roboto-regular"
            onClick={forgotShift}
          >
            Forgot Password
          </Button>
        </Flex>
        <p className="mt-8 text-[14px] roboto-regular text-[#3C4A60]">
          Your organization still not on Realigno?
        </p>
        <p className="mt-18 text-sm roboto-regular">
          <Button type="link" className="text-primary roboto-regular">
            Contact Us
          </Button>
        </p>
      </Form>
    </section>
  );
};

export default LoginPage;
