export const AppUserType = Object.freeze({
  SUPERADMIN: "a5f28d4e-9b6a-4a83-bb85-b58bc84b0f85",
  SUBSIDIARY: "3d29f638-9a7f-4e82-8d0a-2b0a243a8a7d",
  PARTNER: "9b77f8f7-4e47-4f56-a9f7-1bc26a8a2a9e",
});


export const userTypeMap = {
  "System": AppUserType.SUPERADMIN,
  "Subsidiary Admin": AppUserType.SUBSIDIARY,
  "Business Partner": AppUserType.PARTNER,
};
