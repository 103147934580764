import { createSlice } from "@reduxjs/toolkit";

const formatToDDMMYY = (date) => {
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, "0");
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const year = String(d.getFullYear()).slice(-2);
  return `${day}/${month}/${year}`;
};

const formatToDateTime = (date) => {
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, "0");
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const year = String(d.getFullYear()).slice(-2);
  const hours = String(d.getHours()).padStart(2, "0");
  const minutes = String(d.getMinutes()).padStart(2, "0");
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    idToken: "",
    userId: "",
    userName: "",
    name: "",
    code: "",
    dateFormat: formatToDDMMYY(new Date()),
    dateAndTimeFormat: formatToDateTime(new Date()),
  },
  reducers: {
    isLogin(state) {
      state.isAuthenticated = true;
    },
    isLogout(state) {
      state.isAuthenticated = false;
    },
    setIdToken(state, action) {
      state.idToken = action.payload;
    },
    setUserId(state, action) {
      state.userId = action.payload;
    },
    setUserName(state, action) {
      state.userName = action.payload;
    },
    setName(state, action) {
      state.name = action.payload;
    },
    setCode(state, action) {
      state.code = action.payload;
    },
    setDateFormat(state, action) {
      state.dateFormat = formatToDDMMYY(action.payload);
    },
    setDateAndTimeFormat(state, action) {
      state.dateAndTimeFormat = formatToDateTime(action.payload);
    },
  },
});

export const {
  isLogin,
  isLogout,
  setIdToken,
  setUserId,
  setUserName,
  setName,
  setCode,
  setDateFormat,
  setDateAndTimeFormat,
} = authSlice.actions;

export default authSlice.reducer;
