import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cookies from "react-cookies";

import {
  Dropdown,
  Switch,
  Layout,
  Space,
  Drawer,
  Menu,
  Select,
  Avatar,
  message,
} from "antd";
import {
  SettingOutlined,
  HomeOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import * as AntIcons from "@ant-design/icons";
import { AppUserType, userTypeMap } from "../constants/Constant";

import { ReactComponent as DrawerIcon } from "../assets/drawer.svg";
import { toggleTheme } from "../store/slices/theme-slice";
import {
  allSubsidiaries,
  setLogoUrl,
  setRoleName,
  setRoleTypeId,
  setSubsidiaryName,
  setTenantId,
  setTenantName,
  setTenantSubsidiaryId,
  setUserType,
} from "../store/slices/tenant-slice";
import { isLogout, setName } from "../store/slices/auth-slice";
import "../assets/css/Slider.css";
import { getRequest } from "./api/api";
import logo_Url from "../assets/logo.svg";
import { postRequest } from "./api/api";

const { Header } = Layout;
const { Option } = Select;

const TopHeader = ({ collapsed, setCollapsed }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [options, setOptions] = useState([]);
  const [menuItems, setMenuItems] = useState([]);

  const isDarkMode = useSelector((state) => state.theme.isDarkMode);
  const logoUrl = useSelector((state) => state.tenant.logoUrl);
  const tenantId = useSelector((state) => state.tenant.tenantId);
  const name = useSelector((state) => state.auth.name);
  const roleName = useSelector((state) => state.tenant.roleName) || "role";

  const subsidiaryName = useSelector((state) => state.tenant.subsidiaryName);
  const userId = useSelector((state) => state.auth.userId);
  const tenantSubsidiaryId = useSelector(
    (state) => state.tenant.tenantSubsidiaryId
  );

  const navigate = useNavigate();

  const [drawerVisible, setDrawerVisible] = useState(false);

  const handleThemeChange = (checked) => {
    dispatch(toggleTheme());
  };

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await getRequest("/tenantSubsidiaries");

        console.log("response option", response);

        setOptions(response);
      } catch (error) {
        console.error("Failed to fetch tenant subsidiaries:", error);
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    loadMenus();
  }, [tenantId, tenantSubsidiaryId, userId]);

  const getIcon = (iconName) => {
    return AntIcons[iconName] ? React.createElement(AntIcons[iconName]) : null;
  };
  const loadMenus = async () => {
    try {
      let api = `/role-types/getModulesByUserId`;
      const payload = {
        tenantId,
        tenantSubsidiaryId,
        userId,
      };
      const response = await postRequest(api, payload);
      if (response) {
        const groupedData = response.reduce((acc, module) => {
          const category = acc[module.ModuleCategory] || {
            key: module.ModuleCategoryID,
            label: module.ModuleCategory,
            icon: getIcon(module.CatIcon),
            children: [],
          };
          category.children.push({
            key: module.NavigateUrl,
            label: module.Module,
            icon: getIcon(module.Icon),
          });
          acc[module.ModuleCategory] = category;
          return acc;
        }, {});

        setMenuItems(Object.values(groupedData));
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const handleMenuClick = (item) => {
    navigate(item.key);
    setDrawerVisible(false);
  };

  const renderMenuItem = (item) => {
    if (item.children) {
      return (
        <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
          {item.children.map(renderMenuItem)}
        </Menu.SubMenu>
      );
    }

    return (
      <Menu.Item
        key={item.key}
        icon={item.icon}
        className={`custom-menu-item ${
          item.key === location.pathname ? "active" : ""
        }`}
        onClick={() => handleMenuClick(item)}
      >
        {item.label}
      </Menu.Item>
    );
  };

  const logoutHandler = () => {
    dispatch(isLogout());

    localStorage.clear();

    navigate("/");
  };

  const items = options.map((option) => ({
    key: option.tenantSubsidiaryId,
    label: (
      <div
        className="flex gap-6"
        onClick={() =>
          handleSelect({
            roleName: option.roleName,
            name: option.name,
            tenantSubsidiaryId: option.tenantSubsidiaryId,
          })
        }
      >
        <img className="w-6 h-6" src={option.logoUrl} />{" "}
        {option.tenantSubsidiaryName}
      </div>
    ),
  }));

  const [selectedSubsidiary, setSelectedSubsidiary] = useState({
    roleName: roleName,
    name: name,
    tenantSubsidiaryId: tenantSubsidiaryId,
  });

  const handleSelect = async (subsidiary) => {
    setSelectedSubsidiary(subsidiary);

    const extractedData = options.find(
      (data) => data.tenantSubsidiaryId === subsidiary.tenantSubsidiaryId
    );

    localStorage.setItem("name", extractedData.name);
    localStorage.setItem("logoUrl", extractedData.logoUrl);
    localStorage.setItem("roleName", extractedData.roleName);
    localStorage.setItem("roleTypeId", extractedData.roleTypeId);
    localStorage.setItem("tenantId", extractedData.tenantId);
    localStorage.setItem("tenantName", extractedData.tenantName);
    localStorage.setItem(
      "tenantSubsidiaryId",
      extractedData.tenantSubsidiaryId
    );
    localStorage.setItem(
      "tenantSubsidiaryName",
      extractedData.tenantSubsidiaryName
    );

    localStorage.setItem("userType", userTypeMap[extractedData.userType]);

    dispatch(setName(extractedData.name));
    dispatch(setRoleName(extractedData.roleName));
    dispatch(setRoleTypeId(extractedData.roleTypeId));
    dispatch(setLogoUrl(extractedData.logoUrl));
    dispatch(setTenantId(extractedData.tenantId));
    dispatch(setTenantSubsidiaryId(extractedData.tenantSubsidiaryId));
    dispatch(setSubsidiaryName(extractedData.tenantSubsidiaryName));
    dispatch(setTenantName(extractedData.tenantName));

    dispatch(setUserType(userTypeMap[extractedData.userType]));

    const responseLog = await postRequest("/users/updateuserlogs", {
      tenantId,
      tenantSubsidiaryId,
    });

    if (responseLog[0].result) {
      message.success("Subsidiary changed!");
    }

    navigate("/dashboard");
  };

  useEffect(() => {
    if (name && roleName && tenantSubsidiaryId) {
      setSelectedSubsidiary({
        roleName: roleName,
        name: name,
        tenantSubsidiaryId: tenantSubsidiaryId,
      });
    }
  }, [roleName, name, tenantSubsidiaryId]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 640) {
        setDrawerVisible(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Header className="sticky top-0 h-12 z-10 shadow flex items-center justify-between p-0 bg-[#F5F5F5]">
      <Space className="ml-5">
        <DrawerIcon
          className="sm:hidden mr-2 text-[#3C4A60D9] cursor-pointer"
          onClick={() => setDrawerVisible(true)}
        />
        <DrawerIcon
          className="hidden sm:block mr-2 text-[#3C4A60D9] cursor-pointer"
          onClick={() => setCollapsed(!collapsed)}
        />
        <Space className="flex items-center">
          <img src={logo_Url} />
          <h5 className=" hidden md:block text-[#7E93AB] roboto-bold text-[1.25em]">
            Re-Aligno
          </h5>
        </Space>
      </Space>
      <Space className="flex items-center">
        <img src={logoUrl} className="w-12 h-[1.6rem]" />
        <h5 className="text-[#7E93AB] roboto-medium text-[16px]">
          {subsidiaryName}
        </h5>
      </Space>

      <div className="flex mr-4 items-center space-x-4">
        <div>
          <Dropdown
            menu={{
              items,
            }}
            trigger={["click"]}
          >
            <Space>
              <p className="hidden md:block">
                {`${selectedSubsidiary.name} (${selectedSubsidiary.roleName})`}
              </p>
              <AntIcons.UserOutlined className="ml-2" />
            </Space>
          </Dropdown>
        </div>
        <Switch
          className="ml-2 hidden md:block"
          checked={isDarkMode === "dark"}
          onChange={handleThemeChange}
          checkedChildren="Dark"
          unCheckedChildren="Light"
        />
        <LogoutOutlined
          className="w-4 h-4 text-[16px] cursor-pointer"
          onClick={logoutHandler}
        />
      </div>

      <Drawer
        title="Menu"
        placement="left"
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        className="sm:hidden"
      >
        <Menu
          theme="light"
          mode="inline"
          className="custom-menu"
          onClick={handleMenuClick}
        >
          {menuItems.map(renderMenuItem)}
        </Menu>
      </Drawer>
    </Header>
  );
};

export default TopHeader;
