import axios from "axios";
export const base_URL = "https://realignoapi.realigno.com";

export const apiClient = axios.create({
  baseURL: "https://realignoapi.realigno.com",
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Function for making GET requests
export const getRequest = async (url) => {
  try {
    const response = await apiClient.get(url);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Function for making POST requests
export const postRequest = async (url, data) => {
  try {
    const response = await apiClient.post(url, data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Function for making PUT requests
export const putRequest = async (url, data) => {
  try {
    const response = await apiClient.put(url, data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Function for making PATCH requests
export const patchRequest = async (url, data) => {
  try {
    const response = await apiClient.patch(url, data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Function for making DELETE requests
export const deleteRequest = async (url) => {
  try {
    const response = await apiClient.delete(url);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
